import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        
     <h4>The question of whether Machines Can Think... is about as relevant as the question of whether Submarines Can Swim.</h4>
<h5>-E. W. Dijkstra</h5>
      </header>
    </div>
  );
}

export default App;
